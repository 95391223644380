import React, { useContext, useEffect, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { TagsFilter } from "../TagsFilter";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button } from "@material-ui/core";
import GroupsIcon from "@material-ui/icons/Group";
import TicketsListGroup from "../TicketsListGroup";
import MessageOutlined from "@material-ui/icons/MessageOutlined";
import { ReactComponent as ChatDots } from "bootstrap-icons/icons/chat-dots.svg";
import TicketsListAll from "../TicketsListAll";
import { set } from "date-fns";
import TicketsFilterMenu from "../TicketsFilterMenu";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: 120,
    width: 120,
    fontWeight: 700,
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    height: "50px",
    padding: theme.spacing(1),
  },

  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
    height: "40px",
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 11,
    outlineStyle: "none",
    border: "none",
    padding: "10px",
    fontSize: "12px",
  },

  badge: {
    right: "0px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
  searchContainer: {
    display: "flex",
    padding: "10px",
    borderBottom: "2px solid rgba(0, 0, 0, .12)",
    height: "40px"
  },
  icons: {
    width: "20px",
    height: "20px"
  }
}));

const TicketsManager = () => {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [openInvisible, setOpenInvisible] = useState(true);
  const [groupInvisible, setGroupInvisible] = useState(true);
  const [openMessageUnread, setOpenMessageUnread] = useState(false);
  const [pendingMessageUnread, setPendingMessageUnread] = useState(false);
  const [groupMessageUnread, setGroupMessageUnread] = useState(false);
  const [groupPendingMessageUnread, setGroupPendingMessageUnread] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("queue");
  


  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN" || user.profile.toUpperCase() === "SUPERV") {
      setShowAllTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);*/

  //let searchTimeout;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    setSearchParam(searchedTerm);

    if (searchedTerm === "") {

      setTab("open");
      setSearchParam("");
      setSelectedTags([]);
    } else if (tab !== "search") {
      setTab("search");
    }
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map(t => t.id);
    setSelectedTags(tags);
  }

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  useEffect(() => {
    // Atualizando openInvisible com base nos valores de openMessageUnread e pendingMessageUnread
    setOpenInvisible((prev) => !(openMessageUnread || pendingMessageUnread));
    // Atualizando groupInvisible com base nos valores de groupMessageUnread e groupPendingMessageUnread
    setGroupInvisible((prev) => !(groupMessageUnread || groupPendingMessageUnread));

    if (tab === "open") {
      //se a aba aberta for a de tickets individuais atualiza imediatamente o valor de openInvisible para true
      setOpenInvisible((prev) => true);
      setSearchParam("");
      setSelectedTags([]);
    } else if (tab === "group") {
      //se a aba aberta for a de tickets em grupo atualiza imediatamente o valor de groupInvisible para true
      setGroupInvisible((prev) => true);
      setSearchParam("");
      setSelectedTags([]);
    }
  }, [openMessageUnread, pendingMessageUnread, groupMessageUnread, groupPendingMessageUnread, tab]);

  useEffect(() => {
    console.log('openInvisible updated:', openInvisible);
  }, [openInvisible]);

  useEffect(() => {
    console.log('groupInvisible updated:', groupInvisible);
  }, [groupInvisible]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(e) => setNewTicketModalOpen(false)}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            value={"open"}
            icon={
              <Badge
                className={classes.badge}
                variant="dot"
                color="secondary"
                invisible={openInvisible}
              >
                <ChatDots className={classes.icons} />
              </Badge>}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"group"}
            icon={
              <Badge
                className={classes.badge}
                variant="dot"
                color="secondary"
                invisible={groupInvisible}
              >
                <GroupsIcon />
              </Badge>}
            label={i18n.t("tickets.tabs.group.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"search"}
            icon={<SearchIcon />}
            label={i18n.t("tickets.tabs.search.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        {tab === "search" ? (
          <div className={classes.serachInputWrapper}>
            <SearchIcon className={classes.searchIcon} />
            <input
              type="text"
              placeholder={i18n.t("tickets.search.placeholder")}
              className={classes.searchInput}
              value={searchParam}
              onChange={handleSearch}
            />
          </div>
        ) : (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setNewTicketModalOpen(true)}
            >
              {i18n.t("ticketsManager.buttons.newTicket")}
            </Button>
            <Can
              role={user.profile}
              perform="tickets-manager:showall"
              yes={() => (
                <FormControlLabel
                  label={i18n.t("tickets.buttons.showAll")}
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={showAllTickets}
                      onChange={() =>
                        setShowAllTickets((prevState) => !prevState)
                      }
                      name="showAllTickets"
                      color="primary"
                    />
                  }
                />
              )}
            />
          </>
        )}
        <Button
          variant="outlined"
          color="primary"
          aria-controls="filter-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          Filtrar
        </Button>
        <TicketsFilterMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          user={user}
          selectedQueueIds={selectedQueueIds}
          setSelectedQueueIds={setSelectedQueueIds}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
        
      </Paper>
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={openCount}
                color="primary"
              >
                {i18n.t("ticketsList.assignedHeader")}
              </Badge>
            }
            value={"open"}
            classes={{ root: classes.tab }}
          />
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={pendingCount}
                color="secondary"
              >
                {i18n.t("ticketsList.pendingHeader")}
              </Badge>
            }
            value={"pending"}
            classes={{ root: classes.tab }}
          />
          <Tab
            label={"Resolvidos"}
            value={"closed"}
            classes={{ root: classes.tab }}
          />
        </Tabs>
        <Paper className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            selectedFilter={selectedFilter}
            updateCount={(val) => setOpenCount(val)}
            messageUnread={(val) => val > 0 ? setGroupMessageUnread(true) : setGroupMessageUnread(false)
            }
            style={applyPanelStyle("open")}
          />
          <TicketsList
            status="pending"
            showAll={true}
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setPendingCount(val)}
            messageUnread={(val) =>
              val > 0 ? setGroupPendingMessageUnread(true) : setGroupPendingMessageUnread(false)
            }
            style={applyPanelStyle("pending")}
          />
          <TicketsList
            status="closed"
            showAll={true}
            selectedQueueIds={selectedQueueIds}
            style={applyPanelStyle("closed")}
          />
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="group" className={classes.ticketsWrapper}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={openCount}
                color="primary"
              >
                {i18n.t("ticketsList.assignedHeader")}
              </Badge>
            }
            value={"open"}
            classes={{ root: classes.tab }}
          />
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={pendingCount}
                color="secondary"
              >
                {i18n.t("ticketsList.pendingHeader")}
              </Badge>
            }
            value={"pending"}
            classes={{ root: classes.tab }}
          />
          <Tab
            label={"Resolvidos"}
            value={"closed"}
            classes={{ root: classes.tab }}
          />
        </Tabs>
        <Paper className={classes.ticketsWrapper}>
          <TicketsListGroup
            status="open"
            showAll={true}
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setOpenCount(val)}
            messageUnread={(val) =>
              val > 0 ? setOpenMessageUnread(true) : setOpenMessageUnread(false)
            }
            style={applyPanelStyle("open")}
          />
          <TicketsListGroup
            status="pending"
            showAll={true}
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setPendingCount(val)}
            messageUnread={(val) =>
              val > 0 ? setPendingMessageUnread(true) : setPendingMessageUnread(false)
            }
            style={applyPanelStyle("pending")}
          />
          <TicketsListGroup
            status="closed"
            showAll={true}
            selectedQueueIds={selectedQueueIds}
            style={applyPanelStyle("closed")}
          />
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        <TagsFilter onFiltered={handleSelectedTags} />
        <TicketsListAll
          searchParam={searchParam}
          tags={selectedTags}
          showAll={true}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>
    </Paper>
  );
};

export default TicketsManager;
