import sanitizeHtml from 'sanitize-html';
import { isEmail, isStrongPassword } from 'validator';

export function sanitizeInput(input) {
  return sanitizeHtml(input, {
    allowedTags: [],
    allowedAttributes: {},
  });
};

export function isValidEmail(email) {
  if (!email) return false;
  return isEmail(email);
};



