import React, { useState } from "react";
import { Menu, MenuItem, FormControlLabel, Switch, Radio, RadioGroup, FormControl, FormLabel } from "@mui/material";
import TicketsQueueSelect from "../TicketsQueueSelect";

const TicketsFilterMenu = ({ anchorEl, handleClose, user, selectedQueueIds, setSelectedQueueIds, selectedFilter, setSelectedFilter }) => {

  if (!anchorEl) return null; // ✅ Essa verificação pode continuar aqui

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  return (
    <Menu
      id="filter-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem>
        <FormControl component="fieldset">
          <FormLabel component="legend">Filtrar tickets por:</FormLabel>
          <RadioGroup value={selectedFilter} onChange={handleFilterChange}>
            <FormControlLabel
              value="queue"
              control={<Radio />}
              label="Filtrar por fila"
            />
            {selectedFilter === "queue" && (
              <TicketsQueueSelect
                style={{ marginLeft: 6 }}
                selectedQueueIds={selectedQueueIds}
                userQueues={user?.queues || []} // ⚠️ Evita erro se `user?.queues` for undefined
                onChange={(values) => setSelectedQueueIds(values)}
              />
            )}
            <FormControlLabel
              value="fromMe"
              control={<Radio />}
              label="Última mensagem enviada por mim"
            />
            <FormControlLabel
              value="notFromMe"
              control={<Radio />}
              label="Última mensagem enviada pelo contato"
            />
          </RadioGroup>
        </FormControl>
      </MenuItem>
    </Menu>
  );
};

export default TicketsFilterMenu;
