import openSocket from "socket.io-client";
import { getBackendUrl } from "../config";
import api from "./api";

export async function refreshToken() {
  const { data } = await api.post("/auth/refresh_token");
  if (data) {
    localStorage.setItem("token", JSON.stringify(data.token));
    return data.token;
  }
  return null;
}

function connectToSocket() {
  const token = localStorage.getItem("token");
  const socket = openSocket(getBackendUrl(), {
    transports: ['websocket'],
    pingInterval: 10000,
    pingTimeout: 5000,
    query: {
      token: JSON.parse(token),
    },
    //autoConnect: true,
    //reconnection: true,
    //reconnectionAttempts: Infinity,
    //reconnectionDelay: 5000,
    //reconnectionDelayMax: 300000,
  });

  socket.on("disconnect", async (reason) => {
    console.log("Disconnected from socket");
    console.log(reason);
    if (reason === 'io server disconnect') {
      const newToken = await refreshToken();
      if (newToken) {
        socket.disconnect();  // Desconectar o socket atual
        socket.openSocket(getBackendUrl(), {  // Conectar novamente com o novo token
          transports: ['websocket'],
          pingInterval: 10000,
          pingTimeout: 5000,
          query: { token: newToken },
        });
      } else {
        console.error("Falha ao renovar o token");
      }
    }
  });

  /*socket.on("disconnect", (reason) => {
    console.log("Disconnected from socket");
    console.log(reason);
  });

  socket.on("connection", () => {
    console.log("Connected to socket");
  });*/

  return socket;
}

export default connectToSocket;
