import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	TextField,
	InputAdornment,
	IconButton,
	Tooltip,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";
import { ReactComponent as PictureUser } from "bootstrap-icons/icons/person-circle.svg";
import { getBackendUrl } from "../../config";
import { isValidEmail, sanitizeInput } from "../../services/validator";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
});

const UserModal = ({ open, onClose, userId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		email: "",
		password: "",
		profile: "user",
		mediaUrl: "",
		isMediaUrl: false,
		group: false,
	};

	const { user: loggedInUser } = useContext(AuthContext);

	const [user, setUser] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [showPassword, setShowPassword] = useState(false);
	const [whatsappId, setWhatsappId] = useState(false);
	const { loading, whatsApps } = useWhatsApps();
	const [previewUrl, setPreviewUrl] = useState("");

	const [selectedFile, setSelectedFile] = useState(null); // selectedFile: Estado de arquivo selecionado. setSelectedFile: Função para atualizar o estado de arquivo selecionado.
	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
		setMediaFileName(event.target.files[0].name);
		setPreviewUrl(URL.createObjectURL(event.target.files[0]));
	}; // handleFileChange: Função para alterar arquivo.
	const [mediaFileName, setMediaFileName] = useState("");//ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
	const [mediaFile, setMediaFile] = useState(null);
	const [groups, setGroups] = useState(false);

	let url = getBackendUrl();

	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
				setWhatsappId(data.whatsappId ? data.whatsappId : '');
				setGroups(data.group);

				if (data.mediaUrl) {
					const mediaName = data.mediaUrl.split("/").pop();
					const path = `${url}/public/${data.mediaUrl}`;

					console.log("path", path);

					setMediaFileName(mediaName);
					setSelectedFile("temarquivo")
					setMediaFile(data.mediaUrl);
					setPreviewUrl(path);
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
		setMediaFileName("");
		setMediaFile(null);
		setPreviewUrl("");
		setGroups(false);
	};

	const handleSaveUser = async values => {

		const userData = { ...values, whatsappId, queueIds: selectedQueueIds };

		userData.group = groups

		//console.log("userData", userData);

		if (userData.name && !sanitizeInput(userData.name)) {
			toast.error("O nome contém caracteres inválidos");
			return;
		}

		if (userData.email && !isValidEmail(userData.email)) {
			toast.error("O email é inválido");
			return;
		}

		if (userData.password && !sanitizeInput(userData.password)) {
			toast.error(i18n.t("A senha contém caracteres inválidos"));
			return;
		}


		try {
			if (selectedFile === "temarquivo") {

				userData.mediaUrl = mediaFile;

			} else if (selectedFile && selectedFile !== "temarquivo") {

				const formData = new FormData();
				formData.append("file", selectedFile, selectedFile.name.replace(/\s+/g, "_"));

				const { data: file } = await api.post("/files2", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});
				const dataf = file['fullname'];
				userData.mediaUrl = dataf;
			} else {
				userData.mediaUrl = null;
				console.log("userData", userData);
			}

			if (userId) {
				await api.put(`/users/${userId}`, userData);
			} else {
				//verificar em settings a quantidade de usuarios permitidos
				const response = await api.get("/settings");
				const settiings = response.data;
				console.log("settings", settiings);
				const maxUsers = settiings.find(s => s.key === "userQuantity").value;

				const response2 = await api.get("/users");
				const users = response2.data;

				console.log("maxUsers", maxUsers);
				console.log("Users", users.users.length);

				if (users.users.length >= parseInt(maxUsers)) {
					toast.error(i18n.t("Quantidade máxima de usuários atingida"));
					handleClose();
					return;

				} else {
					await api.post("/users", userData);
				}
			}
			toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
		setSelectedFile(null);
		setSelectedQueueIds([]);
		setMediaFile(null);
		setPreviewUrl("");
		setGroups(false);
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div style={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
									{previewUrl
										? (<img src={previewUrl} alt="Preview"
											style={{
												width: 80,
												height: 80,
												objectFit: 'cover',
												borderRadius: "100%"
											}}
										/>)
										: (<PictureUser style={{
											width: 80,
											height: 80,
											objectFit: 'cover',
											borderRadius: "100%",
											color: "#224abe"
										}} />
										)}
								</div>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										name="password"
										variant="outlined"
										margin="dense"
										label={i18n.t("userModal.form.password")}
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										type={showPassword ? 'text' : 'password'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword((e) => !e)}
													>
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											)
										}}
										fullWidth
									/>
								</div>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
									>
										<Can
											role={loggedInUser.profile}
											perform="user-modal:editProfile"
											yes={() => (
												<>
													<InputLabel id="profile-selection-input-label">
														{i18n.t("userModal.form.profile")}
													</InputLabel>

													<Field
														as={Select}
														label={i18n.t("userModal.form.profile")}
														name="profile"
														labelId="profile-selection-label"
														id="profile-selection"
														required
													>
														<MenuItem value="admin">Admin</MenuItem>
														<MenuItem value="superv">Superv</MenuItem>
														<MenuItem value="user">User</MenuItem>
													</Field>
												</>
											)}
										/>
									</FormControl>
								</div>
								<div className={classes.multFieldLine}>
									<FormControlLabel
										control={
											<Field
												as={Checkbox}
												checked={groups}
												name="group"
												color="primary"
												onChange={(e) => {
													setGroups(e.target.checked);
												}}
											/>
										}
										label={"Permitir acesso a grupos"}
									/>
								</div>
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (
										<QueueSelect
											selectedQueueIds={selectedQueueIds}
											onChange={values => setSelectedQueueIds(values)}
										/>
									)}
								/>
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (!loading &&
										<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
											<InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
											<Field
												as={Select}
												value={whatsappId}
												onChange={(e) => setWhatsappId(e.target.value)}
												label={i18n.t("userModal.form.whatsapp")}
											>
												<MenuItem value={''}>&nbsp;</MenuItem>
												{whatsApps.map((whatsapp) => (
													<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
												))}
											</Field>
										</FormControl>
									)}
								/>
							</DialogContent>
							<div>
								{/*	Botão de anexar aquivo */}
								<DialogActions>
									<label htmlFor="contained-button-file">
										<Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
											{mediaFileName ? mediaFileName : "FOTO DO USUÁRIO"}
										</Button>
									</label>
									<div style={{ display: mediaFileName ? "flex" : "none", alignItems: "center" }}>
										<Tooltip title="Remover arquivo" placement="top">
											<IconButton onClick={() => {
												setSelectedFile(null);
												setMediaFileName("");
												setPreviewUrl("");
											}}
												color="error">
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									</div>
									<input
										accept=".jpg,.jpeg,.png"
										style={{ display: "none" }}
										id="contained-button-file"
										type="file"
										onChange={handleFileChange}
									/>
								</DialogActions>
							</div>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default UserModal;
