import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useState, useEffect, useContext } from 'react';
import useWhatsApps from '../../hooks/useWhatsApps';
import { AuthContext } from '../../context/Auth/AuthContext';
import { Can } from "../Can";

import api from '../../services/api';

import { i18n } from "../../translate/i18n";
import { toast } from 'react-toastify';
import Autocomplete from '@material-ui/lab/Autocomplete';
import toastError from '../../errors/toastError';
import { sanitizeInput } from '../../services/validator';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
}));

const daysOfWeek = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

const monthsOfYear = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
];

const AgendaSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
});

const AgendaModal = ({ open, handleClose }) => {
    const classes = useStyles();

    const initialValues = {
        name: '',
        selectedDays: [],
        startTime: '',
        endTime: '',
        multipleEvents: false,
        selfScheduleTime: 0
    };

    const { user: loggedInUser } = useContext(AuthContext);

    const { loading, whatsApps } = useWhatsApps();
    const [whatsappId, setWhatsappId] = useState(false);
    const [selfSchedule, setSelfSchedule] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [routines, setRoutines] = useState(null);
    const [selectedRoutine, setSelectedRoutine] = useState(null);



    useEffect(() => {
        const fetchRoutines = async () => {
            try {
                const { data } = await api.get("/agendas/routine");
                setRoutines(data);

            } catch (error) {
                console.log(error);
                toastError(error);
            }
        };

        fetchRoutines();
    }, []);




    const handleSubmit = async (values) => {

        const routineId = selectedRoutine ? selectedRoutine.id : null;

        if (values.name && !sanitizeInput(values.name)) {
            toast.error("Nome contém caracteres inválidos");
            return;
        }

        const agendaData = {
            ...values,
            whatsappId,
            monthsOfYear: selectedMonth,
            selfSchedule,
            routineId
        };

        console.log(agendaData);

        try {
            const response = await api.post('/agendas', agendaData);

            toast.success('Agenda criada com sucesso!');
        } catch (error) {
            toast.error('Erro ao criar agenda!');
        }

        handleClose();
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Criar Agenda</DialogTitle>
            <DialogContent dividers>
                <Formik
                    initialValues={initialValues}
                    validationSchema={AgendaSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, handleBlur, setFieldValue, isSubmitting, errors, touched }) => (
                        <Form>
                            <div className={classes.multFieldLine}>
                                <Field
                                    name="name"
                                    as={TextField}
                                    label="Nome da agenda"
                                    fullWidth
                                    margin="dense"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                />

                                <Can
                                    role={loggedInUser.profile}
                                    perform="user-modal:editQueues"
                                    yes={() => (!loading &&
                                        <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                                            <InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
                                            <Field
                                                as={Select}
                                                value={whatsappId}
                                                onChange={(e) => setWhatsappId(e.target.value)}
                                                label={i18n.t("userModal.form.whatsapp")}
                                            >
                                                <MenuItem value={''}>&nbsp;</MenuItem>
                                                {whatsApps.map((whatsapp) => (
                                                    <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
                                                ))}
                                            </Field>
                                        </FormControl>
                                    )}
                                />
                            </div>

                            <Typography variant="h6" style={{ fontSize: "1rem", marginTop: 5 }}>
                                Dias da Semana
                            </Typography>

                            <Grid container spacing={2}>
                                {daysOfWeek.map((day) => (
                                    <Grid item xs={6} key={day}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.selectedDays.includes(day)}
                                                    onChange={() => {
                                                        const selected = values.selectedDays.includes(day)
                                                            ? values.selectedDays.filter((d) => d !== day)
                                                            : [...values.selectedDays, day];
                                                        setFieldValue('selectedDays', selected);
                                                    }}
                                                />
                                            }
                                            label={day}
                                        />
                                    </Grid>
                                ))}
                            </Grid>


                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Field
                                        name="startTime"
                                        as={TextField}
                                        label="Horário de Início"
                                        type="time"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.startTime}
                                        error={touched.startTime && Boolean(errors.startTime)}
                                        helperText={touched.startTime && errors.startTime}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="endTime"
                                        as={TextField}
                                        label="Horário de Fim"
                                        type="time"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.endTime}
                                        error={touched.endTime && Boolean(errors.endTime)}
                                        helperText={touched.endTime && errors.endTime}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ marginTop: 4 }}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="multipleEvents"
                                            as={Checkbox}
                                            checked={values.multipleEvents}
                                            onChange={(e) => setFieldValue('multipleEvents', e.target.checked)}
                                        />
                                    }
                                    label="Permitir múltiplos eventos no mesmo horário"
                                />
                            </div>
                            <div style={{ marginTop: 4 }}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="selfSchedule"
                                            as={Checkbox}
                                            checked={selfSchedule}
                                            onChange={(e) => setSelfSchedule(e.target.checked)}
                                        />
                                    }
                                    label="Permitir o auto agendamento"
                                />
                            </div>
                            {selfSchedule && (
                                <>
                                    <Typography variant="h6" style={{ fontSize: "1rem", marginTop: 5 }}>
                                        Duração dos horários para auto agendamento
                                    </Typography>
                                    <div style={{ width: "50%" }}>
                                        <Field
                                            name="selfScheduleTime"
                                            as={TextField}
                                            type="number"
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.selfScheduleTime}
                                            error={touched.selfScheduleTime && Boolean(errors.selfScheduleTime)}
                                            helperText="Tempo em minutos"
                                        />
                                    </div>
                                </>
                            )}
                            {selfSchedule && (
                                <>
                                    <div style={{ marginTop: "10px" }}>
                                        <Autocomplete
                                            multiple
                                            id="tags-outlined"
                                            options={monthsOfYear}
                                            getOptionLabel={(option) => option}
                                            filterSelectedOptions
                                            value={selectedMonth.map(index => monthsOfYear[index])} // Mapear índices para os nomes dos meses
                                            onChange={(event, newValue) => {
                                                // Mapear os nomes dos meses selecionados de volta para seus índices
                                                const selectedMonthIndices = newValue.map(month => monthsOfYear.indexOf(month));
                                                setSelectedMonth(selectedMonthIndices);
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        key={index}
                                                        label={option}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Meses do ano que estará disponível para auto agendamento"
                                                    placeholder="Selecione os meses"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <Autocomplete
                                            style={{ marginTop: 3 }}
                                            options={routines} // Lista de rotinas disponível
                                            filterSelectedOptions // Filtra as rotinas já selecionadas
                                            getOptionLabel={(option) => option.title} // Exibe o nome da rotina
                                            value={selectedRoutine} // Rotina selecionada
                                            onChange={(event, newValue) => {
                                                setSelectedRoutine(newValue);
                                            }}
                                            renderTags={(selected, getTagProps) =>
                                                selected.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        key={option.id} // Identificador único da rotina
                                                        label={option.title} // Exibe o nome da rotina
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Selecione a Rotina"
                                                    placeholder="Escolher rotina"
                                                />
                                            )}
                                        />
                                    </div>
                                </>
                            )}

                            <DialogActions>
                                <Button onClick={handleClose} color="secondary" disabled={isSubmitting}>
                                    Cancelar
                                </Button>
                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                    Salvar
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AgendaModal;
