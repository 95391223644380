import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";

import api from "../../services/api";
import { UsersFilterChat } from "../UsersFilterChat";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import { set } from "date-fns";
import { sanitizeInput } from "../../services/validator";


export function ChatModal({
  open,
  chat,
  type,
  handleClose,
  handleLoadNewChat,
}) {
  const [users, setUsers] = useState([]);
  const [title, setTitle] = useState("");
  const [userOwner, setUserOwner] = useState([0]);

  const { user } = useContext(AuthContext);

  useEffect(() => {


    if (!open) {
      return;
    }

    const fetchData = async () => {

      setUsers([]);
      setTitle("");


      if (type === "edit") {

        setTitle(chat.title);

        try {
          const { data } = await api.get(`/chats/${chat.id}/users`);
          const chatUsers = data;
          const isOwner = chatUsers.some(chatUser => chatUser.ownerId === user.id);
          //let chatUsersList = [];
          let ownerId = [];

          if (isOwner) {
            const userList = chatUsers.filter(chatUser => chatUser.userId !== 0).map(chatUser => ({ id: chatUser.userId }));
            ownerId = chatUsers.filter(chatUser => chatUser.userId === 0).map(chatUser => ({ id: chatUser.ownerId }));

            setUsers(userList);
            setUserOwner(ownerId[0]);

            console.log("userList: ", userList);

          } else if (user.profile === "admin") {

            //userlist tem que listar todos os userId e o ownerId
            const userList = chatUsers.filter(chatUser => chatUser.userId !== 0).map(chatUser => ({ id: chatUser.userId }));
            ownerId = chatUsers.filter(chatUser => chatUser.userId === 0).map(chatUser => ({ id: chatUser.ownerId }));



            //adicionar o ownerId no array de usuários e reordenar os ids em ordem crescente
            userList.push(ownerId[0]);
            userList.sort((a, b) => a.id - b.id);

            setUsers(userList);
            setUserOwner(ownerId[0]);

            console.log("userList: ", userList);


            //setUserOwner(ownerId);

          }
        } catch (err) {
          toastError(err);
        }
      }
    };
    fetchData();

  }, [chat, open, type]);


  const handleSave = async () => {
    try {
      if (!title) {
        alert("Por favor, preencha o título da conversa.");
        return;
      }

      if (!users || users.length === 0) {
        alert("Por favor, selecione pelo menos um usuário.");
        return;
      }

      if (title && !sanitizeInput(title)) {
        toast.error("Título contém caracteres inválidos");
        return;
      }

      if (type === "edit") {
        await api.put(`/chats/${chat.id}`, {
          users,
          title,
        });
      } else {
        console.log("users: ", users);
        console.log("title: ", title);
        const { data } = await api.post("/chats", {
          users,
          title,
        });
        toast.success("Chat criado com sucesso.");
        handleLoadNewChat(data);
      }
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };



  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Conversa</DialogTitle>
      <DialogContent>
        <Grid spacing={2} container>
          <Grid xs={12} style={{ padding: 18 }} item>
            <TextField
              label="Título"
              placeholder="Título"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <UsersFilterChat
              onFiltered={(users) => setUsers(users)}
              initialUsers={users}
              currentUser={user}
              chatOwner={userOwner}



            />
          </Grid>
        </Grid>
        <Typography></Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}